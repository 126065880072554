/* greek-ext */
@font-face {
  font-family: '__Cardo_8a24f5';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/3d99dca18f87d1cd-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Cardo_8a24f5';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/3729966b53976e37-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* latin-ext */
@font-face {
  font-family: '__Cardo_8a24f5';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/cac82a1c29677e36-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Cardo_8a24f5';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/508678ae634f4d8b-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* greek-ext */
@font-face {
  font-family: '__Cardo_8a24f5';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/68ce2a1f98e6226a-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Cardo_8a24f5';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/d1720f3d13092996-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* latin-ext */
@font-face {
  font-family: '__Cardo_8a24f5';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/0dd4e8336b080af6-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Cardo_8a24f5';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/6215ae88e3858384-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Cardo_Fallback_8a24f5';src: local("Times New Roman");ascent-override: 94.16%;descent-override: 34.59%;line-gap-override: 0.00%;size-adjust: 105.17%
}.__className_8a24f5 {font-family: '__Cardo_8a24f5', '__Cardo_Fallback_8a24f5';font-style: normal
}.__variable_8a24f5 {--font-second: '__Cardo_8a24f5', '__Cardo_Fallback_8a24f5'
}

@font-face {
font-family: '__neue_3f2d70';
src: url(/_next/static/media/71014d06ab219670-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__neue_3f2d70';
src: url(/_next/static/media/d37c57873680a63b-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: italic;
}

@font-face {
font-family: '__neue_3f2d70';
src: url(/_next/static/media/939498637f442807-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__neue_3f2d70';
src: url(/_next/static/media/b0d50965c42e99ea-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: italic;
}@font-face {font-family: '__neue_Fallback_3f2d70';src: local("Arial");ascent-override: 74.41%;descent-override: 24.02%;line-gap-override: 19.69%;size-adjust: 101.59%
}.__className_3f2d70 {font-family: '__neue_3f2d70', '__neue_Fallback_3f2d70'
}.__variable_3f2d70 {--font-neue: '__neue_3f2d70', '__neue_Fallback_3f2d70'
}

